import React, { useState, useEffect } from "react";
import axios from "axios";

const NewsList = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);

  // Fetch data from the API
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url);
      setData(response.data.results);
      setNextPage(response.data.next);
      setPrevPage(response.data.previous);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData("https://api.adolat.uz/news/?limit=10&offset=0");
  }, []);

  // Apply the filter to the fetched data after the filter changes
  useEffect(() => {
    if (filter) {
      // Apply filter to already fetched data
      setData((prevData) =>
        prevData.filter((item) =>
          item.title_uz.toLowerCase().includes(filter.toLowerCase())
        )
      );
    } else {
      // Fetch the data again if the filter is cleared (this will re-fetch the first page)
      fetchData("https://api.adolat.uz/news/?limit=10&offset=0");
    }
  }, [filter]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePagination = (pageUrl) => {
    if (pageUrl) {
      fetchData(pageUrl);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Filter by title_uz"
        value={filter}
        onChange={handleFilterChange}
      />
      <ul>
        {data.map((item) => (
          <li key={item.id}>
            <h3>{item.title_uz}</h3>
            {/* Display other details as needed */}
          </li>
        ))}
      </ul>
      <div>
        <button disabled={!prevPage} onClick={() => handlePagination(prevPage)}>
          Previous
        </button>
        <button disabled={!nextPage} onClick={() => handlePagination(nextPage)}>
          Next
        </button>
      </div>
    </div>
  );
};

export default NewsList;
