import styled from "styled-components";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/Breadcrumbs";
import Menu from "../../components/Menu";
import NewsList from "../../components/News/NewComponent";

const BlockContainer = styled(Container)`
  margin: 50px auto;
`;
const BlockContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 67%;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const MeniBlock = styled.div`
  position: sticky;
  width: 30%;
  height: 100%;
  top: 200px;
  @media (max-width: 992px) {
    display: none;
  }
`;
const CardContainer = styled.div`
  display: flex;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  max-height: 350px;
  height: 100%;
  margin: 20px auto;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 638px) {
    flex-direction: column;
    max-height: 550px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
  max-width: 300px;
  width: 100%;
  max-height: 350px;

  @media (max-width: 638px) {
    width: 100%;
    max-width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Name = styled.h2`
  font-size: 1.8rem;
  color: #74767b;
  margin: 10px 0;
  @media (max-width: 786px) {
    font-size: 1.1rem;
  }
`;

const DetailSection = styled.div`
  margin: 20px 0;
`;

const DetailTitle = styled.h3`
  font-size: 1.2rem;
  color: #74767b;
  margin-bottom: 5px;
`;

const Detail = styled.p`
  font-size: 1rem;
  color: #74767b;
  margin: 0 0 10px 0;
`;

const Wings = () => {
  const { component } = useParams();
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get(`https://api.adolat.uz/${component}/`);
        setData(res.data);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleGetData();
  }, [component]);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("OurWings") },
  ];

  const FilterTitle = (item) => {
    switch (item) {
      case "youth":
        return t("TheYouthLaw");
      case "women":
        return t("TheWomensLaw");
      default:
        return t("TheYouthLaw");
    }
  };

  return (
    <BlockContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("OurWings")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <Title>{FilterTitle(component)}</Title>
      <NewsList />
      <BlockContent>
        <Content>
          {Array.isArray(data) &&
            data.map((item) => (
              <Card component={component} key={item.id} data={item} />
            ))}
        </Content>
        <MeniBlock>
          <Menu />
        </MeniBlock>
      </BlockContent>
    </BlockContainer>
  );
};

export default Wings;

function Card({ data }) {
  const { t, i18n } = useTranslation();

  function getLocalizedData(field) {
    return data[`${field}_${i18n.language}`] || data[`${field}_uz`];
  }

  return (
    <CardContainer>
      <ImageContainer>
        <Image src={data.image} alt={getLocalizedData("title")} />
      </ImageContainer>
      <InfoContainer>
        <div>
          <Name>{getLocalizedData("title")}</Name>
        </div>
        <DetailSection>
          <DetailTitle>{t("LeaderLabelEducation")}</DetailTitle>
          <Detail>{getLocalizedData("position")}</Detail>
        </DetailSection>
      </InfoContainer>
    </CardContainer>
  );
}
