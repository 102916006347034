import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import Card from "./Card";

const Block = styled(Container)`
  margin: 50px auto;
  padding: 0 15px;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 50px;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TheMostReadNews = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/news");
        setData(res.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleGetData();
  }, []);

  const filterNews = data.filter((item) => item.views > 1);

  return (
    <Block>
      <Title>{t("MostRead")}</Title>
      <Content>
        {Array.isArray(data) &&
          filterNews
            .slice(0, 6)
            .sort((a, b) => (Number(b.views) || 0) - (Number(a.views) || 0))
            .map((item, index) => (
              <Card element={item} key={index} index={index} />
            ))}
      </Content>
    </Block>
  );
};

export default TheMostReadNews;
